<template>
  <div class="code--view" v-show="isLoaded">
    <div class="nav--bar" v-if="position === 1">
      <img src="../../assets/icons/back.svg" class="back__img" @click="previous">
    </div>
    <div class="code--container">
      <div class="b-carousel js-carousel">
        <div class="b-carousel__wrap js-carousel__wrap">
          <div class="b-carousel__item fade-in">
            <div class="code-first-step--container">
              <p class="title__text">На вашей учетной записи зафиксирована подозрительная активность</p>
              <p class="description__text">В целях безопасности СОВА разрешает доступ к личному кабинету не более чем <br>с 4-х устройств одновременно.</p>
              <div class="button--container">
                <material-button color="white-circle" size="xs" value="НАЗАД" @click.native="exit"></material-button>
                <material-button color="pink-gradient" size="xs" value="ДАЛЕЕ" @click.native="getCode"></material-button>
              </div>
              <p class="description__text md gray">При успешной авторизации будет произведен выход на одном из активных устройств</p>
              <img class="description__img" src="../../assets/img/iphones-many.png">
            </div>
          </div>
          <div class="b-carousel__item fade-out-left">
            <div class="code-second-step--container">
              <p class="description__text" v-if="status === 0 || status === 1">На ваши активные устройства отправлено PUSH-уведомление с кодом подтверждения</p>
              <p class="description__text" v-if="status === 2 || status === 3">На ваш номер <a class="phone__text">{{profile.phone}}</a> был выслан 4-х значный код. Введите его для доступа в профиль</p>
              <!--  ИНПУТ!-->
              <sms-code-input v-model="code" :is-valid="isValid" :code="code"/>
              <p v-if="status === 0" class="description__text sm">Код должен прийти в течение 1 минуты</p>
              <p v-if="status === 1" class="description__text sm">Код не пришел? <a @click="getSmsCode">Отправить СМС</a><br> или напишите нам в чат</p>
              <p v-if="status === 2" class="description__text sm">Код должен прийти в течение 1 минуты</p>
              <p class="timer__text" v-if="status !== 1 && status !== 3">{{ fancyTimeFormat }}</p>
              <div  class="text--container" v-if="status === 3">
                <p class="description__text sm">Возникли проблемы?<br>Напишите нам в чат</p>
                <img class="arrow__img" src="../../assets/icons/arrow.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/button/MaterialButton";
import SmsCodeInput from "@/components/input/SmsCodeInput";
export default {
  name: "CodeView",
  components: {SmsCodeInput, MaterialButton},
  data(){
    return {
      privates: {},
      carousel: {},
      position: 0,
      time: 0,
      code: '',
      isValid: null,
      sc: {
        isPush:false,
        isSms: false,
        time: 0
      },
      isLoaded: false
    }
  },
  watch:{
    code:{
      immediate: true,
      handler(newVal){
        if (newVal.length !== 0){
          this.isValid = null
        }
        if (newVal.length === 4){
          this.validateCode()
        }
      }
    }
  },
  computed:{
    status(){
      if (this.sc?.isPush && !this.sc?.isSms && this.sc?.time > 0){
        return 0
      } else if (this.sc?.isPush && !this.sc?.isSms && this.sc?.time <= 0){
        return 1
      } else if (this.sc?.isSms && this.sc?.time > 0){
        return 2
      } else{
        return 3
      }
    },
    profile(){
      return this.$store.getters.PROFILE
    },
    fancyTimeFormat()
    {
      if (this.sc?.time < 0) return "00:00";
      // Hours, minutes and seconds
      var mins = ~~((this.sc?.time % 3600) / 60);
      var secs = ~~this.sc?.time % 60;

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = "";

      ret += "" + (mins < 10 ? "0" : "");
      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
      return ret;
    }
  },
  methods:{
    initCarousel(setting) {
      this.privates = {}

      /* Privates properties */
      this.privates.setting = setting

      this.privates.sel = {
        'main': document.querySelector(this.privates.setting.main),
        'wrap': document.querySelector(this.privates.setting.wrap),
        'prev': document.querySelector(this.privates.setting.prev),
        'next': document.querySelector(this.privates.setting.next)
      }

      this.privates.opt = {
        'position': 0,
        'max_position': 2
      }
    },

    next() {
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
      window.scrollTo(0,0)
      ++this.privates.opt.position
      ++this.position

      if (this.privates.opt.position >= this.privates.opt.max_position) {
        this.privates.opt.position = 0
      }

      // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
      // setTimeout(() => this.privates.sel.wrap.children[this.privates.opt.position].classList.remove('hidden'), 200)

      this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")


      document.getElementById("form").getElementsByTagName('input')[0].focus()

    },

    previous() {
      this.isAlert = 0
      this.login = ""
      this.password = ""
      this.repeatPassword = ""
      this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-in-right")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
      --this.privates.opt.position
      --this.position

      if (this.privates.opt.position < 0) {
        this.privates.sel.wrap.classList.add('s-notransition')
        this.privates.opt.position = this.privates.opt.max_position - 1
      }

      // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
      this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
      this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")
    },


    async exit(){
      await this.$store.dispatch("EXIT_FROM_ACCOUNT");
      await this.$router.push({name: "AuthView"})
    },


    async getCode(){
      const res = await this.$store.dispatch("GET_CODE");
      const interval = setInterval(() => {
        this.sc.time -= 1;
        if (this.sc?.time <= 0){
          clearInterval(interval);
        }
      },1000)
      if (!res){
        alert("что-то пошло не так")
      } else {
        this.sc = res;
        this.next();
      }
    },

    async validateCode(){
      const res = await this.$store.dispatch("VALIDATE_CODE",{code : this.code});
      if (res){
        this.isValid = true
        if (this.profile.type === 0){
          this.$router.push({name : "ParentView"})
        } else {
          this.$router.push({name : "TeacherView"})
        }
      } else {
        this.code = ""
        this.isValid = false

      }
    },


    async getSmsCode(){
      const res = await this.$store.dispatch("SEND_SMS_CODE");
      this.sc = {...res}
      const interval = setInterval(() => {
        this.sc.time -= 1;
        if (this.sc?.time <= 0){
          clearInterval(interval);
        }
      },1000)
    }
  },
  async mounted() {
    await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
    if (this.profile.isTrusted){
      if (this.profile.type === 0){
        this.$router.push({name : "ParentView"})
      } else {
        this.$router.push({name : "TeacherView"})
      }
    } else {
      this.isLoaded = true
      this.initCarousel({
        'main': '.js-carousel',
        'wrap': '.js-carousel__wrap',
        'prev': '.js-carousel__prev',
        'next': '.js-carousel__next'
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .code--view{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;


    .nav--bar{
      position: absolute;
      top: 0;
      left: 0;

      .back__img{
        width: 20px;
        height: 20px;
        margin-left: 20px;
        margin-top: 16px;
        cursor: pointer;

        @include for-desktop-devices{
          margin-left: 30px;
          margin-top: 30px;
        }
      }
    }

    .code--container{
      width: 336px;
      height: 488px;

      .code-first-step--container{
        display: flex;
        flex-direction: column;
        align-items: center;


        .title__text{
          font-family: Roboto, 'sans-serif';
          font-size: 16px;
          color: #4A4A4A;
          text-align: center;
          letter-spacing: 0;
          width: 336px;
          line-height: 19px;
        }

        .button--container{
          margin-top: 58px;
          margin-bottom: calc(68px - 19px);
          display: flex;
          justify-content: space-between;
          width: 311px;
        }

        .description__text{
          margin-top: 19px;
          font-family: Roboto, 'sans-serif';
          font-size: 16px;
          color: #4A4A4A;
          text-align: center;
          letter-spacing: 0;
          width: 336px;
          line-height: 19px;
          font-weight: 300;


          &.md{
            font-size: 14px;
            font-family: Roboto, 'sans-serif';
            font-weight: 300;
            color: #818181;
            text-align: center;
            letter-spacing: 0;
            line-height: 16px;
            height: 57px;
          }

          &.gray{
            color: #818181;
          }


        }

        .description__img{
          margin-top: 41px;
          width: 309px;
          height: 94px;
        }


      }

      .code-second-step--container{
        margin-top: -60px;


        .text--container{
          display: flex;
          flex-direction: column;
          align-items: center;
          img{
            margin-top: 11px;
          }
        }

        .description__text{
          font-family: Roboto, 'sans-serif';
          font-weight: 300;
          font-size: 16px;
          color: #4A4A4A;
          text-align: center;
          line-height: 20px;
          .phone__text{
            color: #4A90E2;
            line-height: 20px;
            font-weight: 300;
            font-family: Roboto, 'sans-serif';
            font-size: 16px;
          }


          &.sm{
            font-family: Roboto, 'sans-serif';
            margin-top: 50px;
            font-size: 13px;
            color: #4A4A4A;
            text-align: center;
            line-height: 15px;


            a{
              color:#4A90E2;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .timer__text{
          font-family: Roboto, 'sans-serif';
          font-weight: bold;
          font-size: 18px;
          color: #4A4A4A;
          letter-spacing: 2px;
          text-align: center;
          margin-top: 8px;
        }
      }
    }
    }





  .b-carousel {
    position: relative;
    height: 100%;
  }

  .b-carousel__prev,
  .b-carousel__next {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 50px;
    height: 50px;
    background: #fff;
    transform: translateY(-50%) translateZ(0);
    cursor: pointer;
    text-indent: 100%;
    white-space: nowrap;
    z-index: 3;
  }

  .b-carousel__next {
    left: auto;
    right: 20px;
  }

  .b-carousel__wrap {
    display: flex;
    transition: opacity .5s;
    will-change: opacity;
    position: relative;
    z-index: 1;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .b-carousel__item {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .over-hidden{
    overflow: hidden;
  }

  .hidden{
    display: none;
  }


  /* ----------------------------------------------
* Generated by Animista on 2020-7-14 14:41:35
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation fade-in
   * ----------------------------------------
   */
  @-webkit-keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
* Generated by Animista on 2020-7-14 14:43:33
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation fade-out-left
   * ----------------------------------------
   */
  @-webkit-keyframes fade-out-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
  }
  @keyframes fade-out-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
  }


  .fade-in-right {
    -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  .fade-out-left {
    display: none;
    pointer-events: none;
  }
</style>
